import { configureStore } from "@reduxjs/toolkit";
import { vehicleSlice } from "./Reducer/VehicleSlice";
import DriverSlice, { driverSlice } from "./Reducer/DriverSlice";



export const Store = configureStore({
  reducer: {
     vehicles:vehicleSlice.reducer,
     drivers:driverSlice.reducer
  },
});