import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import * as yup from "yup";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CountryCode from "../CountryCode.json";
import { getfleetprofile } from "../Redux/Actions/FleetActions/GetFleetProfile";
import { fleetefitprofile } from "../Redux/Actions/FleetActions/EditFleetProfile";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { ProfileContext } from "../Context/ProfileContext";

const validationschemaforEditdetails = yup.object({
  company_name: yup.string().required("Required"),
  profile_image: yup.string().required("Required"),
  company_email: yup
    .string()
    .email("Invalid email address")
    .required("Required"),

  phone_number: yup
    .string()
    .required("Required")
    .matches(/^\d+$/, "Phone number must be digits only")
    .min(7, "Phone number must be at least 7 digits long"),
});

export default function Documents() {
  const [showEditItem, setShowEditItem] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const { profileData, setProfileData } = useContext(ProfileContext);

  const handleCloseEditItem = () => setShowEditItem(false);
  const handleShowEditItem = () => {
    setShowEditItem(true);
    setShowPopover(false); // Close the popover when opening the modal
  };

  const handleOpenConfirmationModal = () => {
    setShowEditItem(false); // Close the delete modal~
    setShowConfirmationModal(true); // Open the confirmation modal
  };

  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // popover
  const popover = (
    <Popover id="popover-basic" className="new-popover">
      <Popover.Header as="h3" onClick={handleShowEditItem}>
        Delete account
      </Popover.Header>
    </Popover>
  );

  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [fleetdetails, setfleetdetails] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const [curruntImage, setCurruntImage] = useState("");

  const [flag, setflag] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getfleetprofile())
      .then((response) => {
        console.log(response, "fleet data");
        if (response?.payload?.data?.status === 200) {
          setCurruntImage(response?.payload?.data?.data?.profile_image);

          setfleetdetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  const initialvaluesEditdetails = {
    company_name: "",
    company_email: "",
    country_code: "",
    phone_number: "",
    profile_image: null,
  };

  const handleProfileedit = (values, resetForm) => {
    const formData = new FormData();
    formData.append("company_name", values.company_name);
    formData.append("company_email", values.company_email);
    formData.append("country_code", values.country_code);
    formData.append("phone_number", values.phone_number);
    if (values.profile_image) {
      formData.append("profile_image", values.profile_image);
    }
    dispatch(fleetefitprofile(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setfleetdetails((prevState) => ({
            ...prevState,
            ...res.payload.data.data,
          }));
          toast.success(res?.payload?.data?.message);
          setProfileData(!profileData);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // setCurruntImage("");
      setFieldValue("profile_image", file);
      const url = URL.createObjectURL(file);
      setImagePreview(url);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="user-search">
          <Formik
            initialValues={fleetdetails || initialvaluesEditdetails}
            enableReinitialize={true}
            validationSchema={validationschemaforEditdetails}
            onSubmit={(values) => handleProfileedit(values)}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <div className="edit-profile-page">
                  <div className="edit-flex-text">
                    <div className="main-heading">
                      <h2>Documents</h2>
                    </div>
                  </div>

                  <div>
                    <div className="documents-profile-details mt-4">
                      <div className="document-card mb-2">
                        <div className="edit-file-input">
                          <input
                            type="file"
                            accept="application/pdf"
                            id="businessRegistration"
                            class="file-input "
                            name="business_reg"
                          />
                          <label
                            for="businessRegistration"
                            class="custom-file-upload"
                          >
                            <div className="edit-document">
                              <img
                                src={
                                  require("../Assets/Images/edit.svg").default
                                }
                              />
                            </div>
                          </label>
                        </div>
                        <div className="documment-content">
                          <div className="document">
                            <img
                              src={require("../Assets/Images/document.png")}
                            />
                          </div>
                          <p>Document 1</p>
                        </div>
                      </div>
                      <div className="document-card">
                        <div className="edit-file-input">
                          <input
                            type="file"
                            accept="application/pdf"
                            id="businessRegistration"
                            class="file-input "
                            name="business_reg"
                          />
                          <label
                            for="businessRegistration"
                            class="custom-file-upload"
                          >
                            <div className="edit-document">
                              <img
                                src={
                                  require("../Assets/Images/edit.svg").default
                                }
                              />
                            </div>
                          </label>
                        </div>
                        <div className="documment-content">
                          <div className="document">
                            <img
                              src={require("../Assets/Images/document.png")}
                            />
                          </div>
                          <p>Document 2</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* First Modal: Delete Account */}
        <Modal
          className="updated-modal "
          show={showEditItem}
          onHide={handleCloseEditItem}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modal-custom-box">
              <div className="modal-main-heading">
                <h1>Delete Account</h1>
                <p>Are you sure you want to delete your account?</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="footer-button">
            <Button variant="secondary" onClick={handleOpenConfirmationModal}>
              Yes
            </Button>
            <Button variant="primary" onClick={handleCloseEditItem}>
              No
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Second Modal: Confirmation */}
        <Modal
          className="confirmation-modal updated-modal"
          show={showConfirmationModal}
          onHide={handleCloseConfirmationModal}
          //   centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modal-custom-box">
              <div className="modal-main-heading">
                <h1>Request send successfully</h1>
                <p>
                  Your request to delete account has been send to the Admin. Our
                  team will contact you very shortly.
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="footer-button">
            <Button
              variant="secondary done-modal-btn"
              onClick={handleCloseConfirmationModal}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Layout>
  );
}
