import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function TermConditions() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters ">
          <div className="inner-filter-field mb-5">
            <div className="image-heading-text">
              <h2>Term & conditions</h2>
            </div>
            <div className="term-conditions-data">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                non turpis vel nunc vestibulum luctus. Nunc condimentum sem vel
                erat condimentum sollicitudin. Duis pulvinar augue vitae eros
                posuere sagittis. Donec cursus sagittis sagittis. Duis ut tortor
                augue. Phasellus nec consequat lacus. Donec id quam vitae nulla
                lobortis viverra vitae rutrum massa. Quisque mauris elit,
                euismod at lacinia mattis, rhoncus non purus. Mauris a nibh
                nulla. Pellentesque nec dui vitae turpis pulvinar maximus.
                Phasellus placerat condimentum consequat.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                non turpis vel nunc vestibulum luctus. Nunc condimentum sem vel
                erat condimentum sollicitudin. Duis pulvinar augue vitae eros
                posuere sagittis. Donec cursus sagittis sagittis. Duis ut tortor
                augue. Phasellus nec consequat lacus. Donec id quam vitae nulla
                lobortis viverra vitae rutrum massa. Quisque mauris elit,
                euismod at lacinia mattis, rhoncus non purus. Mauris a nibh
                nulla. Pellentesque nec dui vitae turpis pulvinar maximus.
                Phasellus placerat condimentum consequat.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                non turpis vel nunc vestibulum luctus. Nunc condimentum sem vel
                erat condimentum sollicitudin. Duis pulvinar augue vitae eros
                posuere sagittis. Donec cursus sagittis sagittis. Duis ut tortor
                augue. Phasellus nec consequat lacus. Donec id quam vitae nulla
                lobortis viverra vitae rutrum massa. Quisque mauris elit,
                euismod at lacinia mattis, rhoncus non purus. Mauris a nibh
                nulla. Pellentesque nec dui vitae turpis pulvinar maximus.
                Phasellus placerat condimentum consequat.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                non turpis vel nunc vestibulum luctus. Nunc condimentum sem vel
                erat condimentum sollicitudin. Duis pulvinar augue vitae eros
                posuere sagittis. Donec cursus sagittis sagittis. Duis ut tortor
                augue. Phasellus nec consequat lacus. Donec id quam vitae nulla
                lobortis viverra vitae rutrum massa. Quisque mauris elit,
                euismod at lacinia mattis, rhoncus non purus. Mauris a nibh
                nulla. Pellentesque nec dui vitae turpis pulvinar maximus.
                Phasellus placerat condimentum consequat.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
