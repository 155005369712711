import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../../Services/AdminApi";



export const addvehicles = createAsyncThunk(
    "addvehicles",
    async(details) => {
        const data = await AdminAPI.post(`/fleet/addCar`, details);
       return data;
    } 
  );