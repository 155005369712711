import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../../Services/AdminApi";



export const getvehiclesdetail = createAsyncThunk(
    "getvehiclesdetail",
    async(details) => {
        const data = await AdminAPI.get(`/fleet/getVehicleDetail?vehicleId=${details?.id}`);
       return data;
    } 
  );