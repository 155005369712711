import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";

export default function ProfileCreated() {
  const navigate = useNavigate();

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="center-otp-part">
                <div className="login-cmn-box">
                  <div className="otp-varification-page">
                    <div className="green-icon ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="120"
                        height="120"
                        viewBox="0 0 120 120"
                        fill="none"
                      >
                        <path
                          d="M50.0355 10.5193C43.0277 8.25078 35.4095 11.4063 32.0583 17.9658L28.0294 25.8516C27.5507 26.7886 26.7886 27.5507 25.8516 28.0294L17.9658 32.0583C11.4063 35.4095 8.25078 43.0277 10.5193 50.0355L13.2466 58.4605C13.5707 59.4615 13.5707 60.5395 13.2466 61.5405L10.5193 69.9655C8.25078 76.9735 11.4063 84.5915 17.9658 87.943L25.8516 91.9715C26.7886 92.4505 27.5507 93.2125 28.0294 94.1495L32.0583 102.035C35.4095 108.595 43.0277 111.75 50.0355 109.482L58.4605 106.754C59.4615 106.43 60.5395 106.43 61.5405 106.754L69.9655 109.482C76.9735 111.75 84.5915 108.595 87.943 102.035L91.9715 94.1495C92.4505 93.2125 93.2125 92.4505 94.1495 91.9715L102.035 87.943C108.595 84.5915 111.75 76.9735 109.482 69.9655L106.754 61.5405C106.43 60.5395 106.43 59.4615 106.754 58.4605L109.482 50.0355C111.75 43.0277 108.595 35.4095 102.035 32.0583L94.1495 28.0294C93.2125 27.5507 92.4505 26.7886 91.9715 25.8516L87.943 17.9658C84.5915 11.4063 76.9735 8.25078 69.9655 10.5193L61.5405 13.2466C60.5395 13.5706 59.4615 13.5707 58.4605 13.2466L50.0355 10.5193ZM33.7993 58.787L40.8704 51.7155L55.0125 65.858L83.297 37.5737L90.368 44.6448L55.0125 80L33.7993 58.787Z"
                          fill="#0DA600"
                        />
                      </svg>
                    </div>
                    <div className="text-heading">
                      <h1>Congratulations</h1>
                      <p>
                        Your profile has been created successfully. We are
                        reviewing your details and document. You will hear from
                        us soon.
                      </p>
                    </div>
                    <div className="cart-bottom-btn">
                      <button
                        variant="primary"
                        type="submit"
                        className="submit"
                        onClick={() => navigate("/")}
                      >
                        Done
                      </button>
                      <button
                        variant="primary"
                        type="submit"
                        className="submit add-to-cart"
                      >
                        Add Cars
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
