import filledStar from "../Assets/Images/star-fill.svg";
import emptyStar from "../Assets/Images/star-empty.svg";

const Rating = ({ rating = 0 }) => {
  // Define the total number of stars
  const totalStars = 5;

  // Create an array based on the rating value, rounding it to the nearest integer
  const starsArray = Array.from({ length: totalStars }, (_, index) => (
    index < Math.round(rating) ? filledStar : emptyStar
  ));

  return (
    <div className="rating user-id">
      {starsArray.map((star, index) => (
        <img key={index} src={star} alt="star" />
      ))}
      <p>{rating ? rating.toFixed(1) : "0.0"}</p>
    </div>
  );
};

export default Rating;
