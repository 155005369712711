import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import Layout from "../Components/Layout/Layout";
import { Table } from "react-bootstrap";

export default function Dashboard() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="dashboard-upper-cards">
              <div className="dashboard-card">
                <div className="driver-img">
                  <img src={require("../Assets/Images/driver.png")} />
                </div>
                <div className="dashboard-card-content">
                  <h2>Total drivers</h2>
                  <h3>200</h3>
                </div>
              </div>
              <div className="dashboard-card vehicles-card">
                <div className="driver-img">
                  <img src={require("../Assets/Images/car.png")} />
                </div>
                <div className="dashboard-card-content">
                  <h2>Total vehicles</h2>
                  <h3>100</h3>
                </div>
              </div>
              <div className="dashboard-card earnings-card">
                <div className="driver-img">
                  <img src={require("../Assets/Images/dollar.png")} />
                </div>
                <div className="dashboard-card-content">
                  <h2>Total earnings</h2>
                  <h3>$2000</h3>
                </div>
              </div>
              <div className="dashboard-card active-vehicles-card">
                <div className="driver-img">
                  <img src={require("../Assets/Images/car.png")} />
                </div>
                <div className="dashboard-card-content">
                  <h2>Active vehicles</h2>
                  <h3>25</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Today’s earning ($2500)</h2>
              <Link className="view-all">
                View All
                <i>
                  <svg
                    fill="rgba(33, 56, 126, 1)"
                    width="15px"
                    height="15px"
                    viewBox="-128 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                    </g>
                  </svg>
                </i>
              </Link>
            </div>
            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Vehicle no.</th>
                    <th>Assigned to</th>
                    <th className="earnings-heading">
                      <tr>
                        <th colspan="2">Earnings</th>
                      </tr>

                      <tr>
                        <th>Cash</th>
                        <th>Online</th>
                      </tr>
                    </th>
                    <th>Total earning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>XYZ123</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="p-0">
                      <tr>
                        <td>
                          <div className="user-id">
                            <p>$100</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>$400</p>
                          </div>
                        </td>
                      </tr>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>$500</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="drivers-rating">
          <div className="filters w-50">
            <div className="inner-filter-field">
              <div className="image-heading-text">
                <h2>Driver’s rate below 3.0</h2>
                <Link className="view-all">
                  View All
                  <i>
                    <svg
                      fill="rgba(33, 56, 126, 1)"
                      width="15px"
                      height="15px"
                      viewBox="-128 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                      </g>
                    </svg>
                  </i>
                </Link>
              </div>
              <div class="table-responsive">
                <Table size="sm" className="table-cmn">
                  <thead>
                    <tr>
                      <th>S.no.</th>
                      <th>Driver name</th>
                      <th>Rating</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>03</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>04</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>05</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>06</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="filters w-50">
            <div className="inner-filter-field">
              <div className="image-heading-text">
                <h2>Driver’s rate above 4.0</h2>
                <Link className="view-all">
                  View All
                  <i>
                    <svg
                      fill="rgba(33, 56, 126, 1)"
                      width="15px"
                      height="15px"
                      viewBox="-128 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                      </g>
                    </svg>
                  </i>
                </Link>
              </div>
              <div class="table-responsive">
                <Table size="sm" className="table-cmn">
                  <thead>
                    <tr>
                      <th>S.no.</th>
                      <th>Driver name</th>
                      <th>Rating</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>03</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>04</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>05</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>06</td>
                      <td>
                        <div className="first-user">
                          <div className="user-profile">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="user-id">
                              <p>James</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="rating user-id">
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-fill.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <img
                            src={
                              require("../Assets/Images/star-empty.svg").default
                            }
                          />
                          <p>0.2</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-page">
          {/* <div className="edit-profile-page">
            <div className="dash-image">
              <img src={require("../Assets/Images/dashbord-upcoming.png")} />
            </div>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
