import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { getDriverdetail } from "../Redux/Actions/DriverActions/GetDriverDetails";
import { useDispatch } from "react-redux";
import Rating from "../Common/Rating";
import ConfirmModal from "../modals/ConfirmModal";

export default function AddDriver() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const { id } = useParams();
  const dispatch = useDispatch()
  const [driverDetails, setDriverDetails] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [selectedType, setSelectedType] = useState("")

  const handleconfirmModalOpen = (type) => {
    setConfirmModal(true)
    setSelectedType(type)
  }

  const handleconfirmModalClose = () => {
    setConfirmModal(false)
    setSelectedType("")
  }

  useEffect(() => {
    dispatch(getDriverdetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setDriverDetails(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <Layout>
      <Container fluid className="g-0">
        <div className=" driver-filter add-driver-bg-color">
          <div className="inner-filter-field ">
            <div className="driver-page-bg driver-new-req-det">
              <div className="driver-prof">
                <div className="driver-info">
                  <div className="profile-image">
                    <img
                      src={driverDetails?.profile_image ? `${url}${driverDetails?.profile_image}` : require("../Assets/Images/driver-prof.svg")}
                      alt="Current Profile"
                    />
                    <div>
                      <h2>{driverDetails?.first_name}</h2>
                      <Rating rating={driverDetails?.rating} />
                    </div>
                  </div>
                  <div className="driver-add">
                    <p>
                      <span> Email id :- </span> {driverDetails?.email}
                    </p>
                    <p>
                      <span> Phone no. :- </span>{driverDetails?.country_code} {driverDetails?.phone_number}
                    </p>
                  </div>
                </div>
                <div className="approve-btn">

                  {driverDetails?.is_active_by_fleet == 0 ?
                    <button onClick={() => handleconfirmModalOpen("DriverBlock")}>Block Driver</button> :
                    <button onClick={() => handleconfirmModalOpen("DriverBlock")}>Active Driver</button>
                  }
                  <button onClick={() => handleconfirmModalOpen("DeleteDriver")}> Delete Driver</button>
                </div>
              </div>
              <div className="documents-add">
                <h2>Documents</h2>
              </div>
              <div className="driver-documents-grid">
                <div className="documents-count">
                  <img src={require("../Assets/Images/documnet-driver.png")} />
                  <p>Document 1</p>
                </div>
                <div className="documents-count">
                  <img src={require("../Assets/Images/documnet-driver.png")} />
                  <p>Document 2</p>
                </div>
                <div className="documents-count">
                  <img src={require("../Assets/Images/documnet-driver.png")} />
                  <p>Document 3</p>
                </div>
              </div>
            </div>

            <div className="car-driver">
              <div className="car-driving-box full-input-width">
                <h2>Car driving</h2>
                <div className="car-name">
                  <div>
                    <p>Volvo XYZ</p>
                    <span>XYZ123</span>
                  </div>
                  <div className="car-option">
                    <img
                      src={require("../Assets/Images/car-option.svg").default}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="car-driving-box">
                <h2>Car owner</h2>
                <div className="car-name">
                  <div className="car-owner">
                    <img
                      src={require("../Assets/Images/driver-prof.svg").default}
                    />
                    <p>John</p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="total-earnings-grid">
              <div className="earnings-text">
                <h2>Total earnings</h2>
                <p>$500</p>
              </div>
              <div className="earnings-text">
                <h2>Total earnings by cash</h2>
                <p>$50</p>
              </div>
              <div className="earnings-text">
                <h2>Tips</h2>
                <p>$40</p>
              </div>
            </div>

            <div className="table-trips p-4">
              <div className="image-heading-text">
                <h2>Trips</h2>
              </div>
              <div className="search-tab ">
                <div className="input-search ">
                  <Form>
                    <div className="search-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M19 19L13 13"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <circle
                          cx="8"
                          cy="8"
                          r="7"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search " />
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <div class="table-responsive">
                <Table size="sm" className="table-cmn">
                  <thead>
                    <tr>
                      <th>S.no.</th>
                      <th>Date & time</th>
                      <th>Car name</th>
                      <th>Pickup & Dropoff</th>
                      <th>Payment mode</th>
                      <th>Total earning</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>XYZ123</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>XYZ123</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>XYZ123</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Online</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>XYZ123</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Online</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>XYZ123</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>XYZ123</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="reviews mb-5">
              <div className="review-heading">
                <h2>Reviews</h2>
                <h3>
                  4.0
                  <img
                    src={require("../Assets/Images/star-fill-big.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill-big.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill-big.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill-big.svg").default}
                  />
                  <img
                    src={
                      require("../Assets/Images/star-fill-empty-big.svg")
                        .default
                    }
                  />
                </h3>
              </div>
              <div className="driver-reviews mt-3">
                <div className="driver-name-pic">
                  <img
                    src={require("../Assets/Images/driver-prof.svg").default}
                  />
                  <h3>Brenda</h3>
                </div>
                <div className="rating user-id mt-2">
                  <p>4.5</p>
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                </div>
                <p className="reviews-text">
                  Integer porta nisi molestie diam dapibus, vel maximus ligula
                  pulvinar. Nullam non est maximus, interdum mi eu, vulputate
                  sapien. Integer sagittis metus sed urna lobortis, vitae
                  tristique justo consectetur.
                </p>
              </div>

              <div className="driver-reviews mt-3">
                <div className="driver-name-pic">
                  <img
                    src={require("../Assets/Images/driver-prof.svg").default}
                  />
                  <h3>Brenda</h3>
                </div>
                <div className="rating user-id mt-2">
                  <p>4.5</p>
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                </div>
                <p className="reviews-text">
                  Integer porta nisi molestie diam dapibus, vel maximus ligula
                  pulvinar. Nullam non est maximus, interdum mi eu, vulputate
                  sapien. Integer sagittis metus sed urna lobortis, vitae
                  tristique justo consectetur.
                </p>
              </div>

              <div className="driver-reviews mt-3">
                <div className="driver-name-pic">
                  <img
                    src={require("../Assets/Images/driver-prof.svg").default}
                  />
                  <h3>Brenda</h3>
                </div>
                <div className="rating user-id mt-2">
                  <p>4.5</p>
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                </div>
                <p className="reviews-text">
                  Integer porta nisi molestie diam dapibus, vel maximus ligula
                  pulvinar. Nullam non est maximus, interdum mi eu, vulputate
                  sapien. Integer sagittis metus sed urna lobortis, vitae
                  tristique justo consectetur.
                </p>
              </div>

              <div className="driver-reviews mt-3">
                <div className="driver-name-pic">
                  <img
                    src={require("../Assets/Images/driver-prof.svg").default}
                  />
                  <h3>Brenda</h3>
                </div>
                <div className="rating user-id mt-2">
                  <p>4.5</p>
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                </div>
                <p className="reviews-text">
                  Integer porta nisi molestie diam dapibus, vel maximus ligula
                  pulvinar. Nullam non est maximus, interdum mi eu, vulputate
                  sapien. Integer sagittis metus sed urna lobortis, vitae
                  tristique justo consectetur.
                </p>
              </div>

              <div className="driver-reviews mt-3">
                <div className="driver-name-pic">
                  <img
                    src={require("../Assets/Images/driver-prof.svg").default}
                  />
                  <h3>Brenda</h3>
                </div>
                <div className="rating user-id mt-2">
                  <p>4.5</p>
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-empty.svg").default}
                  />
                </div>
                <p className="reviews-text">
                  Integer porta nisi molestie diam dapibus, vel maximus ligula
                  pulvinar. Nullam non est maximus, interdum mi eu, vulputate
                  sapien. Integer sagittis metus sed urna lobortis, vitae
                  tristique justo consectetur.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <ConfirmModal
        confirmModal={confirmModal}
        handleconfirmModalClose={handleconfirmModalClose}
        selectedType={selectedType}
        Idval={id}
        is_active={driverDetails?.is_active_by_fleet}

      />
    </Layout>
  );
}
