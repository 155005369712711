import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../../Services/AdminApi";


export const fleetefitprofile = createAsyncThunk(
    "fleetefitprofile",
    async (details) => {
      const data = await AdminAPI.put(`/fleet/createProfile`, details);
      console.log(data);
      return data;
    }
  );


