import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Notifications() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters ">
          <div className="inner-filter-field mb-5">
            <div className="image-heading-text">
              <h2>Notifications</h2>
            </div>
            <div className="notifications-data">
              <div className="notify-card">
                <h5>Driver Status Updates</h5>
                <p>Driver Sarah has completed a ride. Total earnings: $15.</p>
                <div>11 sep 2024, 11:59 AM</div>
              </div>
              <div className="notify-card">
                <h5>Vehicle Maintenance Reminders</h5>
                <p>Reminder: Your vehicle (Honda Civic) is due for maintenance on Sept 30.</p>
                <div>11 sep 2024, 11:59 AM</div>
              </div>
              <div className="notify-card">
                <h5>Earnings Summary</h5>
                <p>This week, your fleet earned $500 from completed rides..</p>
                <div>11 sep 2024, 11:59 AM</div>
              </div>
              <div className="notify-card">
                <h5>Driver Performance Feedback</h5>
                <p>Driver Sarah received a 5-star rating from her last passenger!</p>
                <div>11 sep 2024, 11:59 AM</div>
              </div>
              <div className="notify-card">
                <h5>New Driver Applications</h5>
                <p>New driver application received from Mark. Review his application.</p>
                <div>11 sep 2024, 11:59 AM</div>
              </div>
              <div className="notify-card">
                <h5>Fleet Utilization Reports</h5>
                <p>Your fleet utilization rate is at 75%. Keep it up!</p>
                <div>11 sep 2024, 11:59 AM</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
