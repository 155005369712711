import { Container, Form, Table } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { VehiclesManagement } from "../Redux/Actions/VehiclesActions/GetVehicles";
import SearchBar from "../Common/SearchBar";
import { format } from "date-fns";
import PaginationComponet from "../Components/Pagination";

export default function Vehicles() {
  const vehiclesdata = useSelector((state) => state?.vehicles?.alldata);
  console.log(vehiclesdata);

  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [type, settype] = useState(1);
  const [activeToggle, setActiveToggle] = useState("activetab");

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      VehiclesManagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    dispatch(
      VehiclesManagement({
        search: search,
        limit: limit,
        page: page,
        type: type,
      })
    );
  }, [search, limit, page, flag, type]);

  const handlenavigate = () => {
    navigate("/add-car");
  };


  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text vehicles-heading">
              <h2>Vehicles</h2>
              <div className="add-car-bn">
                <button
                  variant="primary"
                  type="submit"
                  className="add-more-vehicles-btn"
                  onClick={handlenavigate}
                >
                  Add More Vehicles
                </button>
              </div>
            </div>
            <div className="search-tab ">
              <div className="input-search full-input-width">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <SearchBar setSearchValue={setsearch} />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="user-search-passengers">
              <div className="drop-down">
                <div className="toggle-box-top">
    
                  <div
                    className={`toggle-btn ${
                      activeToggle === "activetab" ? "toggle-active" : ""
                    }`}
                    onClick={() => {setActiveToggle("activetab");settype(1)}} 
                  >
                    <p>Active</p>
                  </div>

                 
                  <div
                    className={`toggle-btn ${
                      activeToggle === "Inactive" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("Inactive");
                      settype("0");
                    }} 
                  >
                    <p>Inactive</p>
                  </div>
                  <div
                    className={`toggle-btn ${
                      activeToggle === "pending" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("pending");
                      settype(2);
                    }} 
                  >
                    <p>Pending</p>
                  </div>
                  <div
                    className={`toggle-btn ${
                      activeToggle === "rejected" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("rejected");
                      settype(3);
                    }} 
                   
                  >
                    <p>Rejected</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>Sr.no.</th>
                    <th>Vehicle no.</th>
                    <th>Added on</th>
                    <th>Assigned to</th>
                    <th>Total earning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vehiclesdata?.data?.myVehicles?.map((res, index) => {
                     const serialNumber = (page - 1) * limit + index + 1;   

                    return (
                      <tr >
                        <td>{serialNumber}</td>
                        <td>{res?.reg_number || "N/A"}</td>
                        <td>
                          {res?.createdAt
                            ? format(new Date(res.createdAt), "dd MMM yyyy")
                            : "N/A"}
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img src={require("../Assets/Images/user.png")} />
                              <div className="user-id">
                                <p>James</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>$500</td>
                        <td>
                          <Link to={`/vehicle-details/${res?._id}`}>
                            <div className="user-view">
                              <img
                                src={
                                  require("../Assets/Images/view-icon.svg")
                                    .default
                                }
                              />
                            </div>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
     
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, vehiclesdata.data?.totalCount)} of{" "}
                    {vehiclesdata.data?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={vehiclesdata.data?.totalCount}
                    limit={vehiclesdata.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
