import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { uploaddocument } from "../Redux/Actions/AuthActions";
import { useDispatch } from "react-redux";
import PdfLogo from "../Assets/Images/pdf-file.svg"

const validationSchema = Yup.object({
  business_reg: Yup.mixed().required("Business Registration is required"),
  vat: Yup.mixed().required("VAT Identification Number is required"),
});

export default function UploadDocuments() {


  const dispatch = useDispatch();

  const Token = sessionStorage.getItem('token')
  console.log(Token);

  const initialValues = {
    business_reg: null, 
    vat: null,
  };

  const handleSubmit = (values) => {
    
    const formData = new FormData();
    if (values.business_reg) {
      formData.append("business_reg", values.business_reg);
    }
    if (values.vat) {
      formData.append("vat", values.vat);
    }

    dispatch(uploaddocument(formData))
    .then((res) => {
      if (res?.payload?.data?.status === 200) {
        toast.success("Documents uploaded Successfully");

        setTimeout(() => {
          toast.dismiss();
          window.location.href = "/bank-details";
        }, 1500);
      } else {
        toast.error(res?.payload?.data?.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <Container fluid>
      <div className="sign-banner-part">
        <div className="inner-login-box">
        <div className="center-align-box">
                <div className="center-bank-details">
                  <div className="login-cmn-box">


                  <div className="otp-varification-page">
                     

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="otp-verification-page">
                <div className="otp-heading">
                  <h1>Upload documents</h1>
                  </div>
                  {/* Business Registration */}
                  <div className="business-reg">
                    <div className="up-load-doc">
                      <p>Business Registration</p>
                      <input
                        type="file"
                        accept="application/pdf"
                        id="businessRegistration" 
                        className="file-input"
                        name="business_reg"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("business_reg", file); 
                        }}
                      />
                      
                      <ErrorMessage name="business_reg" component="div" className="error-message" />
                      <label htmlFor="businessRegistration" className="custom-file-upload">
                        Upload Document
                      </label>
                      {values.business_reg && (
                        <img
                          src={PdfLogo}
                          alt=" PDF Preview"
                          style={{ maxWidth: "100px", marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>

                  {/* VAT Identification */}
                  <div className="business-reg mt-2">
                    <div className="up-load-doc">
                      <p>VAT Identification Number</p>
                      <input
                        type="file"
                        accept="application/pdf"
                        id="vatIdentification" 
                        className="file-input"
                        name="vat"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("vat", file); 
                        }}
                      />
                      <ErrorMessage name="vat" component="div" className="error-message" />
                      <label htmlFor="vatIdentification" className="custom-file-upload">
                        Upload Document
                      </label>
                      {values.vat && (
                        <img
                          src={PdfLogo}
                          alt=" PDF Preview"
                          style={{ maxWidth: "100px", marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>

                  <button 
                   variant="primary"
                  type="submit"
                   className="submit">
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
      
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </Container>
  );
}
