import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../../Services/AdminApi";



export const getdriverdetail = createAsyncThunk(
    "getdriverdetail",
    async(details) => {
        const data = await AdminAPI.get(`/fleet/getDriverDetails?driverId=${details?.id}`);
       return data;
    } 
  );


  export const addDriverAction = createAsyncThunk(
    "addDriverAction",
    async(details) => {
        const data = await AdminAPI.put(`/fleet/addDriver`,details);
       return data;
    } 
  );



  export const getDriverdetail = createAsyncThunk(
    "getDriverdetail",
    async(details) => {
        const data = await AdminAPI.get(`/fleet/getDriverDetails?driverId=${details?.id}`);
       return data;
    } 
  );


  export const driverDeleteAction = createAsyncThunk(
    "driverDeleteAction",
    async(details) => {
        const data = await AdminAPI.delete(`/fleet/deleteDriver?driverId=${details?.id}`);
       return data;
    } 
  );


  export const driverBlockAction = createAsyncThunk(
    "driverBlockAction",
    async(details) => {
        const data = await AdminAPI.put(`/fleet/blockDriver`,details);
       return data;
    } 
  );

