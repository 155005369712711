import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { registerfleet } from "../Redux/Actions/AuthActions";
import Countrycode from "../CountryCode.json";

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  companyEmail: Yup.string()
    .email("Invalid email format")
    .required("Company email is required"),
  street: Yup.string().required("Street is required"),
  streetNumber: Yup.string().required("Street number is required"),
  city: Yup.string().required("City is required"),
  zipCode: Yup.string().required("Zip code is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be digits only")
    .min(7, "Phone number must be at least 7 digits long"),
  password: Yup.string()
  .min(8, "New password must be at least 8 characters long")
  .matches(/[a-z]/, "Must contain at least one lowercase letter")
  .matches(/[A-Z]/, "Must contain at least one uppercase letter")
  .matches(/[0-9]/, "Must contain at least one number")
  .matches(/[\W_]/, "Must contain at least one special character")
  .required("Password is required"),
  terms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
});

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  console.log(showPassword, "visibe");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const initialstate = {
    companyName: "",
    companyEmail: "",
    streetNumber: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
    street: "",
    password: "",
    country_code: "",
    terms: false,
  };

  const handlesignup = (values) => {
    console.log(values, "valuesss");
    const payload = {
      company_name: values?.companyName,
      company_email: values?.companyEmail,
      street: values?.street,
      street_number: values?.streetNumber,
      city: values?.city,
      zip: values?.zipCode,
      phone_number: values?.phoneNumber,
      country_code: values?.country_code,
      password: values?.password,
    };
    dispatch(registerfleet(payload))
      .then((res) => {
   
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          const userId = res?.payload?.data?.data?._id;
          setTimeout(() => {
            toast.dismiss();
            navigate(`/otp-varification/${userId}`, {
              state: res?.payload?.data,
            });
          }, 1500);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="left-login-banner">
                <img src={require("../Assets/Images/logi-banner.png")} />
              </div>
              <div className="right-banner-part">
                <div className="login-cmn-box">
                  <div className="login-box-inner-wrap sign-up-box">
                    <div className="login-logo">
                      <img
                        src={require("../Assets/Images/login-logo.svg").default}
                      />
                    </div>

                    <Formik
                      initialValues={initialstate}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) =>
                        handlesignup(values, resetForm)
                      }
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        resetForm,
                        setFieldValue,
                        handleBlur,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="form-set">
                            <Form.Group
                              className="mb-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Company name"
                                name="companyName"
                                value={values?.companyName}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="companyName"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Company email"
                                name="companyEmail"
                                value={values?.companyEmail}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="companyEmail"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Street"
                                name="street"
                                value={values?.street}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="street"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Street number"
                                name="streetNumber"
                                value={values?.streetNumber}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="streetNumber"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="text"
                                placeholder="City"
                                name="city"
                                value={values?.city}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="city"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Zip code"
                                name="zipCode"
                                value={values?.zipCode}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="zipCode"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                            <div className="number-drop">
                              <div className="input-number">
                                {/* <Form.Group
                                  className="mb-2 drop-with-number"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Phone no."
                                    name="phoneNumber"
                                    value={values?.phoneNumber}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="phoneNumber"
                                    className="error-message"
                                    component="div"
                                  />
                                </Form.Group> */}

                                <Form.Group
                                  className="mb-3 input-field-width"
                                  controlId="formBasicPhone"
                                >
                                  {/* <Form.Label>Phone Number</Form.Label> */}
                                  <div className="d-flex">
                                    <Form.Select
                                      className="me-2"
                                      style={{ maxWidth: "120px" }}
                                      name="country_code"
                                      value={values?.country_code}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "country_code",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      {Countrycode?.map(
                                        ({ dial_code, name, flag }) => (
                                          <option
                                            key={dial_code}
                                            value={dial_code}
                                          >
                                            {flag} {dial_code}
                                          </option>
                                        )
                                      )}
                                    </Form.Select>
                                    <Form.Control
                                      type="tel"
                                      placeholder="Phone Number"
                                      name="phoneNumber"
                                      value={values?.phoneNumber}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <ErrorMessage
                                    className="error-message"
                                    name="phoneNumber"
                                    component="div"
                                  />
                                </Form.Group>
                                <div className="new-select-box">
                                  {/* <Form.Select aria-label="Default select example">
                                <option>+91</option>
                                <option value="1">2</option>
                              </Form.Select> */}

                                  {/* <Form.Select
                                    className="me-2"
                                    style={{ maxWidth: "120px" }}
                                    name="country_code"
                                    value={values?.country_code}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "country_code",
                                        e.target.value
                                      )
                                    }
                                  >
                                    {Countrycode?.map(
                                      ({ dial_code, name, flag }) => (
                                        <option
                                          key={dial_code}
                                          value={dial_code}
                                        >
                                          {flag} {dial_code}
                                        </option>
                                      )
                                    )}
                                  </Form.Select> */}
                                </div>
                              </div>
                            </div>

                            <Form.Group
                              className="mb-2 pass-eys"
                              controlId="formBasicPassword"
                            >
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                value={values?.password}
                                onChange={handleChange}
                              />

                              {showPassword ? (
                                <img
                                  src={
                                    require("../Assets/Images/eye-fill.svg")
                                      .default
                                  }
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <img
                                  src={
                                    require("../Assets/Images/hidepassword1.svg")
                                      .default
                                  }
                                  onClick={togglePasswordVisibility}
                                />
                              )}
                              <ErrorMessage
                                name="password"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                          </div>
                          <div className="pass-rember-line">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                                name="terms"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="terms-conditions">
                              <p>
                                By signing up with Vibe onn you are agree to our{" "}
                                <span>Term & conditions</span> or{" "}
                                <span>Privacy policy</span>.
                              </p>
                            </div>

                            <div></div>
                          </div>
                          <ErrorMessage
                            name="terms"
                            component="div"
                            className="error-message"
                          />
                          <div className="btn-next">
                            <button
                              variant="primary"
                              type="submit"
                              className="submit"
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <div className="sign-up-text login-text">
                      <p>
                        Already have an account? <Link to="/">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
