import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../../Services/AdminApi";



export const Deletecehicle = createAsyncThunk(
    "Deletecehicle",
    async(details) => {
        const data = await AdminAPI.delete(`/fleet/delVehicle?vehicleId=${details?.vehicleId}`);
       return data;
    } 
  );