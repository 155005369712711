import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getvehiclesdetail } from "../Redux/Actions/VehiclesActions/GetVehicleDetails";
import { format } from "date-fns";
import { FaRegFilePdf } from "react-icons/fa";
import { Deletecehicle } from "../Redux/Actions/VehiclesActions/DeleteVehicle";
import { toast } from "react-toastify";
import { activateDeactivateVehicle } from "../Redux/Actions/VehiclesActions/ActivateDeactivateVehicle";
import ImagePreviewModal from "../Common/ImagePreviewModal";

export default function VehicleDetails() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [vehicledetails, setVehicleDetails] = useState("");
  const [showd, setshowd] = useState(false);
  const [show, setshow] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getvehiclesdetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setVehicleDetails(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const DeleteVehiclehandler = () => {
    dispatch(Deletecehicle({ vehicleId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setshowd(false);
          navigate("/vehicles");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeactivateVehicle = () => {
    const type = vehicledetails?.is_active === 1 ? 0 : 1;
    dispatch(activateDeactivateVehicle({ vehicleId: id, type: type }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setshow(false);
          navigate("/vehicles");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePrivewImagesOpen = (img) => {
    setImageModal(true);
    setSelectedImage(img);
  };

  const handlePrivewImagesClose = () => {
    setImageModal(false);
    setSelectedImage("");
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text vehicles-heading vehicle-details">
              <div>
                <h2>{vehicledetails?.reg_number || "N/A"}</h2>
                <div className="info-details">
                  <p>
                    Car manufacturer:{" "}
                    <span>{vehicledetails?.manufacturer || "N/A"}</span>
                  </p>
                  <p>
                    Added on:{" "}
                    <span>
                      {" "}
                      {vehicledetails?.createdAt
                        ? format(
                            new Date(vehicledetails.createdAt),
                            "dd MMM yyyy"
                          )
                        : "N/A"}
                    </span>
                  </p>
                  <p>
                    Registered in:{" "}
                    <span> {vehicledetails?.reg_in || "N/A"}</span>
                  </p>
                  <p>
                    Price per km: <span>$10/km</span>
                  </p>
                </div>
              </div>
              <div className="vehicles-btn">
                <button
                  variant="primary"
                  type="submit"
                  className="add-more-vehicles-btn deactivate-vehicle-btn"
                  onClick={() => {
                    setshow(true);
                  }}
                >
                  {vehicledetails?.is_active === 1
                    ? "Deactivate Vehicle"
                    : "Activate Vehicle"}
                </button>
                <button
                  variant="primary"
                  type="submit"
                  className="remove-vehicle-btn"
                  onClick={() => setshowd(true)}
                >
                  Remove Vehicle
                </button>
              </div>
            </div>
            <div className="documents-heading-vehicle-details">
              <h2>Documents</h2>
            </div>
            <div className="documents-vehicle-details">
              <div className="document-card">
                <div className="edit-file-input"></div>
                <div className="documment-content">
                  <div className="document">
                    {vehicledetails?.reg_doc ? (
                      vehicledetails?.reg_doc ?.includes("pdf") ? (
                        <a
                          href={`${url}${vehicledetails?.reg_doc }`}
                          target="_blank"
                        >
                         <FaRegFilePdf size={100}  color="red" />
                          
                        </a>
                      ) : (
                        <img
                          onClick={() =>
                            handlePrivewImagesOpen(vehicledetails.reg_doc)
                          }
                          src={`${url}${vehicledetails.reg_doc}`}
                          alt="Document Preview"
                          style={{
                            maxWidth: "100px",
                          }}
                        />
                      )
                    ) : (
                      "Document not uploaded"
                    )}
                  </div>
                  <p>Document 1</p>
                </div>
              </div>
              <div className="document-card">
                <div className="edit-file-input"></div>
                <div className="documment-content">
                  <div className="document">
                    {vehicledetails?.rental_concession ? (
                      vehicledetails?.rental_concession?.includes("pdf") ? (
                        <a
                          href={`${url}${vehicledetails?.rental_concession}`}
                          target="_blank"
                        >
                          <FaRegFilePdf size={100} color="red" />
                        </a>
                      ) : (
                        <img
                          onClick={() =>
                            handlePrivewImagesOpen(
                              vehicledetails?.rental_concession
                            )
                          }
                          src={`${url}${vehicledetails?.rental_concession}`}
                          alt="Document Preview"
                          style={{
                            maxWidth: "100px",
                          }}
                        />
                      )
                    ) : (
                      "Document not uploaded"
                    )}
                  </div>
                  <p>Document 2</p>
                </div>
              </div>
              <div className="document-card">
                <div className="edit-file-input"></div>
                <div className="documment-content">
                  <div className="document">
                    {vehicledetails?.insurance ? (
                      vehicledetails.insurance?.includes("pdf") ? (
                        <a
                          href={`${url}${vehicledetails.insurance}`}
                          target="_blank"
                        >
                          <FaRegFilePdf size={100} color="red" />
                        </a>
                      ) : (
                        <img
                          onClick={() =>
                            handlePrivewImagesOpen(vehicledetails?.insurance)
                          }
                          src={`${url}${vehicledetails?.insurance}`}
                          alt="Document Preview"
                          style={{
                            maxWidth: "100px",
                          }}
                        />
                      )
                    ) : (
                      "Document not uploaded"
                    )}
                  </div>
                  <p>Document 3</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vehicle-details-card">
          <div className="filters">
            <div className="inner-filter-field">
              <div className=" ">
                <div className="vehicle-card">
                  <h1>Currently assigned</h1>
                  <div className="vehicle-inner-card">
                    <div>
                      <div className="user-img">
                        <img src={require("../Assets/Images/user.png")} />
                      </div>
                      <div className="vehicle-card-content">
                        <h2>John </h2>
                        <div>
                          <div className="rating user-id currently-assigned-rating">
                            <p>4.5</p>
                            <div>
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link>
                      <div className="details-img">
                        <img
                          src={require("../Assets/Images/details.svg").default}
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filters">
            <div className="inner-filter-field">
              <div className="total-earnings-card">
                <div className="total-earnings-card-content">
                  <h2>Total earnings</h2>
                  <h3>$1000</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text vehicles-heading">
              <h2>Trips</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search full-input-width">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="Search " />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>Sr.no.</th>
                    <th>Date & time</th>
                    <th>Driver name</th>
                    <th>Pickup & Dropoff</th>
                    <th>Payment mode</th>
                    <th>Total earning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location1.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Cash</td>
                    <td>$500</td>
                    <td>
                      <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                      <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className=" user-profile pickup-dropoff-location">
                          <img
                            src={
                              require("../Assets/Images/location2.svg").default
                            }
                          />
                          <div className="user-id pickup-dropoff">
                            <p>Pickup location</p>
                            <p> Dropoff location</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Online</td>
                    <td>$500</td>
                    <td>
                    <Link to="/trip-details">
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </Link>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <ImagePreviewModal
          handlePrivewImagesClose={handlePrivewImagesClose}
          imageModal={imageModal}
          selectedImage={selectedImage}
        />
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h5 className="are-sure-text mt-5">
            Are you sure you want to Delete this Vehicle?
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <button className="theme-btn done-btn" onClick={DeleteVehiclehandler}>
            Yes
          </button>
          <button
            className="theme-btn done-btn"
            onClick={() => setshowd(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h5 className="are-sure-text mt-5">
            {vehicledetails?.is_active === 1
              ? "Are you sure you want to Deactivate this Vehicle?"
              : "Are you sure you want to Activate this Vehicle?"}
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <button
            className="theme-btn done-btn"
            onClick={handleDeactivateVehicle}
          >
            Yes
          </button>
          <button className="theme-btn done-btn" onClick={() => setshow(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
