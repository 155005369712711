import React from 'react'
import { Modal } from 'react-bootstrap'
import { addDriverAction, driverBlockAction, driverDeleteAction } from '../Redux/Actions/DriverActions/GetDriverDetails';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ConfirmModal = ({ confirmModal, handleconfirmModalClose, selectedType, Idval, is_active }) => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()

    const handledriverDelete = () => {
        dispatch(
            driverDeleteAction({ id: Idval })
        )
            .then((res) => {
                console.log(res);
                if (res?.payload?.data?.status === 200) {
                    toast.success(res?.payload?.data?.message);
                    Navigate("/driver")
                } else {
                    toast.error(res?.payload?.data?.message);

                }
            })
            .catch((err) => {
                console.log(err, "Error occurred");
            });
    }

    const handledriverBlock = () => {
        dispatch(driverBlockAction({ driverId: Idval, type: is_active == 0 ? 1 :0})).then((res) => {
            console.log(res);
            if (res?.payload?.data?.status === 200) {
                toast.success(res?.payload?.data?.message);
                Navigate("/driver")
            } else {
                toast.error(res?.payload?.data?.message);
            }
        })
            .catch((err) => {
                console.log(err, "Error occurred");
            });
    }

    return (
        <Modal show={confirmModal} animation={false} className="delete-popup">
            <Modal.Body className="text-center">

                {selectedType === "DeleteDriver" && <h5 className="are-sure-text mt-5">
                    Are you sure you want to Delete ?
                </h5>}

                {selectedType === "DriverBlock" && <h5 className="are-sure-text mt-5">
                    Are you sure you want to Block ?
                </h5>}

            </Modal.Body>
            <Modal.Footer className="justify-content-center border-none">
                {selectedType === "DeleteDriver" && <button className="theme-btn done-btn" onClick={handledriverDelete} >
                    Yes
                </button>}
                {selectedType === "DriverBlock" && <button className="theme-btn done-btn" onClick={handledriverBlock}  >
                    Yes
                </button>}
                <button
                    className="theme-btn done-btn"
                    onClick={handleconfirmModalClose}
                >
                    No
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal
