import { createSlice } from "@reduxjs/toolkit";
import { VehiclesManagement } from "../Actions/VehiclesActions/GetVehicles";

export const vehicleSlice = createSlice({
    name: "vehicleSlice",
    initialState: {
        alldata: "",
        loading: false,
        error: null,
    },
    reducers: {
        // You can add other reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(VehiclesManagement.pending, (state) => {
                state.loading = true; 
                state.error = null;   
            })
            .addCase(VehiclesManagement.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action.payload.data; 
            })
            .addCase(VehiclesManagement.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default vehicleSlice.reducer;
