import { Link } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import { Container, Form, Table } from "react-bootstrap";

export default function TripDetails() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text vehicles-heading vehicle-details">
              <div>
                <h2>Trip Details</h2>
              </div>
            </div>
            <div className="trip-details-cards">
              <div className="driver-trip-detail-card">
                <h2>Driver</h2>
                <div className="vehicle-inner-card">
                  <div>
                    <div className="user-img">
                      <img src={require("../Assets/Images/user.png")} />
                    </div>
                    <div className="vehicle-card-content">
                      <h2>John </h2>
                      <div>
                        <div className="rating user-id currently-assigned-rating">
                          <p>4.5</p>
                          <div>
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="driver-trip-detail-card">
                <div className="car-details-content ">
                  <h2>Car details</h2>
                  <h3>Volvo XYZ</h3>
                  <p>XYZ123</p>
                </div>
              </div>
              <div className="driver-trip-detail-card">
                <h2>User</h2>
                <div className="vehicle-inner-card">
                  <div>
                    <div className="user-img">
                      <img src={require("../Assets/Images/trip-user.jpeg")} />
                    </div>
                    <div className="vehicle-card-content">
                      <h2>Brenda </h2>
                      <div>
                        <div className="rating user-id currently-assigned-rating">
                          <p>4.5</p>
                          <div>
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vehicle-details-card">
          <div className="filters">
            <div className="inner-filter-field">
              <div className=" ">
                <div className="vehicle-card">
                  <h1>Pickup & drop-off</h1>
                  <div className="vehicle-inner-card">
                    <div>
                      <div className="user-img">
                        <img src={require("../Assets/Images/user.png")} />
                      </div>
                      <div className="vehicle-card-content">
                        <h2>John </h2>
                        <div>
                          <div className="rating user-id currently-assigned-rating">
                            <p>4.5</p>
                            <div>
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link>
                      <div className="details-img">
                        <img
                          src={require("../Assets/Images/details.svg").default}
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filters">
            <div className="inner-filter-field">
              <div className="total-earnings-card">
                <div className="total-earnings-card-content">
                  <h2>Total trip price </h2>
                  <h3>
                    $10
                    <span className="cash-span">(cash)</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text vehicles-heading">
              <h2>Review</h2>
            </div>
            <div className="review-cards">
              <h2>Review given by Brenda (Passenger)</h2>
              <div className="rating user-id currently-assigned-rating">
                <p>4.5</p>
                <div>
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                </div>
              </div>
              <p className="review-para">
                Integer porta nisi molestie diam dapibus, vel maximus ligula
                pulvinar. Nullam non est maximus, interdum mi eu, vulputate
                sapien. Integer sagittis metus sed urna lobortis, vitae
                tristique justo consectetur.
              </p>
            </div>
            <div className="review-cards">
              <h2>Review given by John (Driver)</h2>
              <div className="rating user-id currently-assigned-rating">
                <p>4.5</p>
                <div>
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                  <img
                    src={require("../Assets/Images/star-fill.svg").default}
                  />
                </div>
              </div>
              <p className="review-para">
                Integer porta nisi molestie diam dapibus, vel maximus ligula
                pulvinar. Nullam non est maximus, interdum mi eu, vulputate
                sapien. Integer sagittis metus sed urna lobortis, vitae
                tristique justo consectetur.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
