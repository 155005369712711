import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { DriversManagemanet } from "../Redux/Actions/DriverActions/GetDrivers";
import SearchBar from "../Common/SearchBar";
import PaginationComponet from "../Components/Pagination";
import { Button, Modal } from "react-bootstrap";
import AddDriverModal from "../modals/AddDriverModal";
import Rating from "../Common/Rating";

export default function Driver() {

  const url = process.env.REACT_APP_FILE_BASE_URL;
  const driversdata = useSelector((state) => state?.drivers?.alldata);
  console.log(driversdata);
  const [activeToggle, setActiveToggle] = useState("images");
  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [type, settype] = useState("0");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      driversdata({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    dispatch(
      DriversManagemanet({
        search: search,
        limit: limit,
        page: page,
        type: type,
      })
    );
  }, [search, limit, page, flag, type]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Drivers</h2>
              <button onClick={handleShow}>Add More Driver</button>
            </div>
            <div className="search-tab ">
              <div className="input-search  full-input-width">
                <Form>
                  <div className="search-icon ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <SearchBar setSearchValue={setsearch} />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="user-search-passengers">
              <div className="drop-down">
                <div className="toggle-box-top">
                  {/* First toggle (Images) */}
                  <div
                    className={`toggle-btn ${activeToggle === "images" ? "toggle-active" : ""
                      }`}
                    onClick={() => {
                      setActiveToggle("images");
                      settype(1);
                    }} // Set active state to 'images' on click
                  >
                    <p>Active</p>
                  </div>

                  {/* Second toggle (HEIC To JPG) */}
                  <div
                    className={`toggle-btn ${activeToggle === "heicToJpg" ? "toggle-active" : ""
                      }`}
                    onClick={() => {
                      setActiveToggle("heicToJpg");
                      settype("0");
                    }} // Set active state to 'heicToJpg' on click
                  >
                    <p>Blocked</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Driver name</th>
                    <th>Email</th>
                    <th>Phone no.</th>
                    <th>Rating</th>
                    <th>Total earning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {driversdata?.data?.myDrivers?.map((res, index) => {
                    const serialNumber = (page - 1) * limit + index + 1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <div className="first-user">
                            <div
                              className="user-profile"
                              onClick={() => navigate("/user-over-view")}
                            >
                              <img
                                src={res?.profile_image ? `${url}${res?.profile_image}` : require("../Assets/Images/user.png")}
                                alt="Current Profile"
                              />
                              <div className="user-id">
                                <p>{res?.first_name}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>{res?.email}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>{res?.country_code} {res?.phone_number}</p>
                          </div>
                        </td>
                        <td>
                          <Rating rating={res?.rating} />
                        </td>
                        <td>
                          <div className="user-id">
                            <p>N/A</p>
                          </div>
                        </td>


                        <td>
                          <Link to={`/driver-detail/${res?._id}`}>
                            <div className="user-view">
                              <img
                                src={
                                  require("../Assets/Images/view-icon.svg")
                                    .default
                                }
                              />
                            </div>
                          </Link>

                        </td>

                      </tr>
                    );
                  })}

                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, driversdata.data?.totalPages)} of{" "}
                    {driversdata.data?.totalPages} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={driversdata.data?.totalCount}
                    limit={driversdata.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddDriverModal handleClose={handleClose} show={show} />

      </Container>
    </Layout>
  );
}
