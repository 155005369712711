import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../../Services/AdminApi";



export const activateDeactivateVehicle = createAsyncThunk(
    "activateDeactivateVehicle",
    async(details) => {
        const data = await AdminAPI.put(`/fleet/activateDeactivateVehicle`, details);
       return data;
    } 
  );