import { createSlice } from "@reduxjs/toolkit";
import { VehiclesManagement } from "../Actions/VehiclesActions/GetVehicles";
import { DriversManagemanet } from "../Actions/DriverActions/GetDrivers";

export const driverSlice = createSlice({
    name: "DriverSlice",
    initialState: {
        alldata: "",
        loading: false,
        error: null,
    },
    reducers: {
        // You can add other reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(DriversManagemanet.pending, (state) => {
                state.loading = true; 
                state.error = null;   
            })
            .addCase(DriversManagemanet.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action.payload.data; 
            })
            .addCase(DriversManagemanet.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default driverSlice.reducer;
