import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Earning() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="earnings-check">
          <div className="earnings-boxs">
            <p>Total earnings</p>
            <h2>$1000</h2>
          </div>
          <div className="earnings-boxs">
            <p className="d-flex gap-2">
              Earning of Sep 2024
              <Link to="">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="white" />
                  <path
                    d="M12.7813 12.0005L9.48145 8.70062L10.4243 7.75781L14.6669 12.0005L10.4243 16.2431L9.48145 15.3003L12.7813 12.0005Z"
                    fill="#21387E"
                  />
                </svg>
              </Link>
            </p>
            <h2>$500</h2>
          </div>
          <div className="earnings-boxs">
            <p>Today’s earnings</p>
            <h2>$100</h2>
          </div>
        </div>
        <div className="filters ">
          <div className="inner-filter-field mb-5">
            <div className="image-heading-text">
              <h2>Trips</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search ">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="Search " />
                  </Form.Group>
                </Form>
              </div>
              <div className="filter-btn">
                <button>Filter</button>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Date & time</th>
                    <th>Vehicle no.</th>
                    <th>Assigned to</th>
                    <th className="earnings-heading">
                      <tr>
                        <th colspan="2">Earnings</th>
                      </tr>

                      <tr>
                        <th >Cash</th>
                        <th >Online</th>
                      </tr>
                    </th>
                    <th>Total earning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>11 sep 2024, 05:00PM</td>
                    <td>XYZ123</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user.png")} />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="p-0">
                      <tr>
                        <td>
                          <div className="user-id">
                            <p>$100</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>$400</p>
                          </div>
                        </td>
                      </tr>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>$500</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
