import { Container } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";
import { addvehicles } from "../Redux/Actions/VehiclesActions/AddVehicles";
import { toast } from "react-toastify";
import cars from "../Constant/Cars.json";
import { useState } from "react";


const validationSchema = Yup.object().shape({
  reg_number: Yup.string().required("Required"),
  manufacturer: Yup.string().required("Required"),

  model: Yup.string().required("Required"),

  reg_in: Yup.string().required("Required"),
  reg_doc: Yup.mixed().required("Required"),

  rental_concession: Yup.mixed().required("Required"),
  insurance: Yup.mixed().required("Required"),
});

export default function AddCar() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [results, setResults] = useState([]);
  const initialstate = {
    reg_number: "",
    manufacturer: "",
    model: "",
    reg_in: "",
    reg_doc: "",
    rental_concession: "",
    insurance: "",
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    // Append each field from the payload to the FormData object
    formData.append("reg_number", values?.reg_number);
    formData.append("manufacturer", values?.manufacturer);
    formData.append("model", values?.model);
    formData.append("reg_in", values?.reg_in);

    if (values?.rental_concession) {
      formData.append("rental_concession", values.rental_concession);
    }

    if (values?.insurance) {
      formData.append("insurance", values.insurance);
    }

    // Append the file directly for reg_doc
    if (values?.reg_doc) {
      formData.append("reg_doc", values.reg_doc);
    }
    dispatch(addvehicles(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/car-added";
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error, "error occur");
      });
  };


  const handleSearch = (value) => {
    if (value) {
      const filteredResults = cars.cars.filter(item =>
        item.company.toLowerCase().includes(value.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  };


  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="center-bank-details">
                <div className="login-cmn-box">
                  {""}
                  <Formik
                    initialValues={initialstate}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="add-car-page">
                          <div className="add-car-heading">
                            <h1> Add Car</h1>
                          </div>
                          <div className="user-info">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Car registration no."
                                name="reg_number"
                                value={values?.reg_number}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="reg_number"
                                component="div"
                                className="error-message"
                              />
                            </Form.Group>
                          </div>
                          <div className="add-car-input">
                            <div className="user-info">
                              {/* <Form.Select
                                className="mb-3"
                                controlId="formBasicEmail"
                                name="manufacturer"
                                value={values?.manufacturer}
                                onChange={handleChange}
                              >
                                <option>Car manufacturer</option>
                              </Form.Select>
                              <ErrorMessage
                                name="manufacturer"
                                component="div"
                                className="error-message"
                              /> */}
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Field
                                  type="search"
                                  placeholder="Search Vehicles"
                                  name="manufacturer"
                                  as={Form.Control}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFieldValue('manufacturer', value);
                                    handleSearch(value); // Call the search function here
                                  }}
                                />
                                {results.length > 0 && (
                                  <ul style={{ border: '1px solid #ccc', maxHeight: '150px', overflowY: 'auto' }}>
                                    {results.map(item => (
                                      <li
                                        key={item.company}
                                        onClick={() => {
                                          setFieldValue("manufacturer", item.company);
                                          setResults([]); // Clear results after selecting
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {item.company}
                                      </li>
                                    ))}
                                  </ul>
                                )}

                                <ErrorMessage
                                  name="manufacturer"
                                  component="div"
                                  className="error-message"
                                />
                              </Form.Group>
                            </div>
                            <div className="user-info">
                              <Form.Select
                                className="mb-3"
                                controlId="formBasicModel" // Updated ID for clarity
                                name="model"
                                value={values?.model}
                                onChange={handleChange} // Handle change for model selection
                              >
                                <option value="">Car Model</option> {/* Placeholder option */}
                                {cars.cars.filter((e)=>e.company == values?.manufacturer).map((item) => (
                                  item.models.map((model) => (
                                    <option key={model} value={model}> {/* Use model as key and value */}
                                      {model} {/* Display the model name */}
                                    </option>
                                  ))
                                ))}
                              </Form.Select>
                              <ErrorMessage
                                name="model"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="add-car-input">
                            <div className="user-info">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Registrated In"
                                  className="registered-in-input"
                                  name="reg_in"
                                  value={values?.reg_in}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="reg_in"
                                  component="div"
                                  className="error-message"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="add-car-input">
                            <div className=" business-reg">
                              <div className="up-load-doc">
                                <p>Vehicle Registration Document</p>
                                <div>
                                  {values?.reg_doc ? (
                                    values.reg_doc.type ===
                                      "application/pdf" ? (
                                      <>
                                        <FaRegFilePdf size={50} color="red" />
                                      </>
                                    ) : (
                                      <img
                                        src={URL.createObjectURL(
                                          values.reg_doc
                                        )}
                                        alt="Document Preview"
                                        style={{
                                          maxWidth: "100px",
                                        }}
                                      />
                                    )
                                  ) : null}

                                  <input
                                    type="file"
                                    accept="application/pdf,image/*"
                                    id="businessRegistration1"
                                    className="file-input"
                                    name="reg_doc"
                                    onChange={(event) => {
                                      const file = event.currentTarget.files[0];
                                      setFieldValue("reg_doc", file);
                                    }}
                                  />

                                  <label
                                    htmlFor="businessRegistration1"
                                    className="custom-file-upload"
                                  >
                                    Upload Document
                                  </label>

                                  <ErrorMessage
                                    name="reg_doc"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=" business-reg">
                              <div className="up-load-doc">
                                <p>Car Rental Concession</p>

                                {values?.rental_concession ? (
                                  values.rental_concession.type ===
                                    "application/pdf" ? (
                                    <>
                                      <FaRegFilePdf size={50} color="red" />
                                    </>
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        values.rental_concession
                                      )}
                                      alt="Document Preview"
                                      style={{
                                        maxWidth: "100px",
                                      }}
                                    />
                                  )
                                ) : null}

                                <input
                                  type="file"
                                  accept="application/pdf,image/*"
                                  id="businessRegistration2"
                                  className="file-input"
                                  name="rental_concession"
                                  onChange={(event) => {
                                    const file = event.currentTarget.files[0];
                                    setFieldValue("rental_concession", file);
                                  }}
                                />

                                <label
                                  htmlFor="businessRegistration2"
                                  className="custom-file-upload"
                                >
                                  Upload Document
                                </label>
                                <ErrorMessage
                                  name="rental_concession"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="add-car-input">
                            <div className=" business-reg add-car-document-3">
                              <div className="up-load-doc">
                                <p>Insurance </p>

                                {values?.insurance ? (
                                  values.insurance.type ===
                                    "application/pdf" ? (
                                    <>
                                      <FaRegFilePdf size={50} color="red"  />
                                    </>
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        values.insurance
                                      )}
                                      alt="Document Preview"
                                      style={{
                                        maxWidth: "100px",
                                      }}
                                    />
                                  )
                                ) : null}

                                <input
                                  type="file"
                                  accept="application/pdf,image/*"
                                  id="businessRegistration3"
                                  className="file-input "
                                  name="insurance"
                                  onChange={(event) => {
                                    const file = event.currentTarget.files[0];
                                    setFieldValue("insurance", file);
                                  }}
                                />

                                <label
                                  htmlFor="businessRegistration3"
                                  className="custom-file-upload"
                                >
                                  Upload Document
                                </label>
                                <ErrorMessage
                                  name="insurance"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="add-car-btn">
                            <button
                              variant="primary"
                              type="submit"
                              className="add-btn"
                            >
                              Add Car
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
