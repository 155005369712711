import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addbankdetails } from "../Redux/Actions/AuthActions";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object({
  account_number: Yup.string()
  .required("Bank account number is required")
  .matches(/^\d+$/, "Account number must be a number"),
    
  bank_name: Yup.string()
    .required("Name of the bank is required")
    .min(1, "Bank name must be at least 1 character long"), 
  iban_number: Yup.string()
    .required("IBAN number is required"),
   
  
  bic_number: Yup.string()
    .required("BIC number is required")
  
});

export default function BankDetails() {
  const dispatch = useDispatch();

  const initialvalues = {
    account_number: "",
    bank_name: "",
    iban_number: "",
    bic_number: "",
  };

  const handleSubmit = (values) => { 
    console.log(values);

    const payload = {
      account_number: values?.account_number,
      bank_name: values?.bank_name,
      iban_number: values?.iban_number,
      bic_number: values?.bic_number,
      is_profile_completed: 1
    };
    dispatch(addbankdetails(payload))
      .then((res) => {
        
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setTimeout(() => {
            toast.dismiss();
            window.location.href = `/profile-created`;
          }, 1500);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="center-bank-details">
                <div className="login-cmn-box">
                  <Formik
                    initialValues={initialvalues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="otp-varification-page">
                          <div className="otp-heading">
                            <h1>Bank details</h1>
                          </div>
                          <div className="bank-details-input">
                            <div className="user-info">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Bank account number"
                                  name="account_number"
                                  value={values?.account_number}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="account_number"
                                  className="error-message"
                                  component="div"
                                />
                              </Form.Group>
                            </div>
                            <div className="user-info">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Name of the bank"
                                  value={values?.bank_name}
                                  name="bank_name"
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="bank_name"
                                  className="error-message"
                                  component="div"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="bank-details-input">
                            <div className="user-info">
                              <Form.Group
                                className=""
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="IBAN no."
                                  name="iban_number"
                                  value={values?.iban_number}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="iban_number"
                                  className="error-message"
                                  component="div"
                                />
                              </Form.Group>
                            </div>
                            <div className="user-info">
                              <Form.Group
                                className=""
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="BIC no."
                                  name="bic_number"
                                  value={values?.bic_number}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="bic_number"
                                  className="error-message"
                                  component="div"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <button
                            variant="primary"
                            type="submit"
                            className="submit"
                          >
                            Next
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
