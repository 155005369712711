import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resendotp, verifyOtp } from "../Redux/Actions/AuthActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export default function OtpVarification() {
  const [countdown, setCountdown] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const location = useLocation();
  console.log("locationlocationlocation", location);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .length(4, "OTP must be 4 digits")
      .required("OTP is required")
      .matches(/^\d+$/, "OTP must be digits only"),
  });

  const initialValues = {
    otp: "",
  };

  const handleSubmit = (values) => {
    dispatch(verifyOtp({ otp: values?.otp, userId: id }))
      .then((res) => {
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          sessionStorage.setItem("token", res?.payload?.data?.data?.token);

          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/upload-documents";
          }, 1500);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResendOtp = (setFieldValue) => {
    dispatch(resendotp({ userId: id }))
      .then((res) => {
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setCountdown(30);
          startCountdown();
          setFieldValue("otp", "");
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startCountdown = () => {
    setIsResendDisabled(true);
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsResendDisabled(false);
          return 30;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startCountdown();
    return () => clearInterval();
  }, []);

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="center-otp-part">
                <div className="login-cmn-box">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="otp-varification-page">
                          <div className="otp-heading">
                            <h1>Enter OTP</h1>
                            <p>
                              Enter the 4 digit OTP send to your email id{" "}
                              <span>
                                {location?.state?.data?.company_email}
                              </span>
                            </p>
                          </div>

                          <div className="form-set set-otp">
                            <OtpInput
                              value={values.otp}
                              onChange={(value) => setFieldValue("otp", value)}
                              numInputs={4}
                              name="otp"
                              renderInput={(props) => <input {...props} />}
                            />
                            <ErrorMessage
                              name="otp"
                              className="error-message"
                              component="div"
                            />
                          </div>
                          <button
                            variant="primary"
                            type="submit"
                            className="submit"
                          >
                            Submit
                          </button>

                          <div className="reset-time mt-2">
                            {isResendDisabled ? (
                              <div className="opt-resent">
                                <p className="resend-button">Resend OTP in</p>
                                <span className="resend-button">{`00:${String(
                                  countdown
                                ).padStart(2, "0")}s`}</span>
                              </div>
                            ) : (
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleResendOtp(setFieldValue)}
                                className="resend-button"
                              >
                                Resend OTP
                              </a>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
