import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";




 export const login = createAsyncThunk(
    "login", 
    async(details) => {
        const { data } = await AdminAPI.post('/fleet/login',  details);
        console.log(data,"datadata");    
        sessionStorage.setItem("token", data?.data?.token);
        sessionStorage.setItem("name", data?.data?.company_name);
        console.log(data?.data?.token);
        return data;
    }
);





// ========= Fleet Owner register =============

export const registerfleet = createAsyncThunk(
    "registerfleet",
    async(details) => {
        const data = await AdminAPI.post(`/fleet/register`,details);
       return data;
    } 
  );

  // ======== verify otp =========

  export const verifyOtp = createAsyncThunk(
    "verifyOtp",
    async(details) => {
        const data = await AdminAPI.post(`/fleet/verifyOtp`,details);
       return data;
    } 
  );

 // ========  resend otp ==========
 
  export const resendotp = createAsyncThunk(
    "resendotp",
    async(details) => {
        const data = await AdminAPI.post(`/fleet/resendOtp`,details);
       return data;
    } 
  );


   // ======== upload document  =========

   export const uploaddocument = createAsyncThunk(
    "uploaddocument",
    async(details) => {
        const data = await AdminAPI.put(`/fleet/createProfile`,details);
       return data;
    } 
  );


     // ======== add bank details =========

     export const addbankdetails = createAsyncThunk(
      "addbankdetails",
      async(details) => {
          const data = await AdminAPI.put(`/fleet/createProfile`,details);
         return data;
      } 
    );


    // ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch(`/fleet/changePassword`, details);
    console.log(data);
    return data;
  }
);




  
