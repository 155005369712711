import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";

import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { login } from "../Redux/Actions/AuthActions";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<span className="error-message">Invalid Email Address</span>)
    .required(<span className="error-message">Required</span>),
  password: Yup.string()
    .min(6, <span className="error-message">Password too short</span>)
    .required(<span className="error-message">Required</span>),
});

export default function Login() {
  
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = (values) => {
    dispatch(login(values))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/dashboard";
          }, 1500);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="left-login-banner">
                <img src={require("../Assets/Images/logi-banner.png")} />
              </div>
              <div className="right-banner-part">
                <div className="login-cmn-box">
                  <div className="login-box-inner-wrap">
                    <div className="login-logo">
                      <img
                        src={require("../Assets/Images/login-logo.svg").default}
                      />
                    </div>

                    <Formik
                      initialValues={{ email: "", password: "" }}
                      validationSchema={LoginSchema}
                      onSubmit={handleLogin}
                    >
                      {({ values, handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="form-set">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={values?.email}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="email" component="div" />
                            </Form.Group>

                            <Form.Group
                              className="mb-2 pass-eys"
                              controlId="formBasicPassword"
                            >
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                value={values?.password}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="password" component="div" />

                              {showPassword ? (
                                <img
                                  src={
                                    require("../Assets/Images/eye-fill.svg")
                                      .default
                                  }
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <img
                                  src={
                                    require("../Assets/Images/hidepassword1.svg")
                                      .default
                                  }
                                  onClick={togglePasswordVisibility}
                                />
                              )}
                            </Form.Group>
                          </div>
                          <button
                            variant="primary"
                            type="submit"
                            className="submit"
                          >
                            Login
                          </button>
                        </Form>
                      )}
                    </Formik>
                    {/* <div className="pass-rember-line">
                      <Link  className="forgot">
                        Forgot Password?
                      </Link>
                    </div> */}

                    <div className="sign-up-text">
                      <p>
                        Don’t have an account?{" "}
                        <span onClick={() => navigate("/sign-up")}>
                          <Link>Sign Up</Link>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
