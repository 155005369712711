import React from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { addDriverAction } from '../Redux/Actions/DriverActions/GetDriverDetails';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const AddDriverModal = ({ handleClose, show }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <Modal className="updated-modal" show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="modal-custom-box">
                    <div className="modal-main-heading">
                        <h1>Add Driver</h1>
                        <div className="user-info mt-4">
                            <Formik
                                initialValues={{ company_name: '', country_code: '', phone_number: '' }}
                                onSubmit={(values) => {
                                    // Extract country code and phone number
                                    const countryCode = values.country_code.slice(0, 3); // Get the first three characters (+91)
                                    const phoneNumber = values.country_code.slice(3); // Get the remaining characters (988442220)

                                    console.log('Form Submitted', values);
                                    dispatch(
                                        addDriverAction({
                                            email: values?.company_name,
                                            phone_number: phoneNumber, // Full phone number
                                            country_code: countryCode // Just the country code
                                        })
                                    )
                                    .then((res) => {
                                        console.log(res);
                                        if (res?.payload?.data?.status === 200) {
                                            toast.success(res?.payload?.data?.message);
                                            handleClose();
                                        } else {
                                            toast.error(res?.payload?.data?.message);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err, "Error occurred");
                                    });
                                }}
                                validate={values => {
                                    const errors = {};
                                
                                    // Validate company_name only if country_code is empty
                                    if (!values.company_name && !values.country_code) {
                                        errors.company_name = 'Required';
                                    } else if (values.company_name && !/\S+@\S+\.\S+/.test(values.company_name)) {
                                        errors.company_name = 'Invalid email address';
                                    }
                                
                                    // Validate country_code only if company_name is empty
                                    if (!values.country_code && !values.company_name) {
                                        errors.country_code = 'Required';
                                    }
                                
                                    return errors;
                                }}
                                
                                
                            >
                                {({ setFieldValue, values }) => (
                                    <FormikForm>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Field
                                                name="company_name"
                                                type="email"
                                                placeholder="Email"
                                                as={Form.Control}
                                            />
                                            <ErrorMessage name="company_name" component="div" className="text-danger" />
                                        </Form.Group>

                                        <div className="line-brack">
                                            <p>or</p>
                                        </div>

                                        <div>
                                            <PhoneInput
                                                international
                                                countryCallingCodeEditable={false}
                                                placeholder="Enter phone number"
                                                value={values.country_code}
                                                onChange={(value) => setFieldValue("country_code", value)}
                                            />
                                            <ErrorMessage name="country_code" component="div" className="text-danger" />
                                        </div>

                                        <Modal.Footer className="footer-button">
                                            <Button type="submit" className="full-input-width" variant="secondary">
                                                Add Driver
                                            </Button>
                                        </Modal.Footer>
                                    </FormikForm>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddDriverModal;
