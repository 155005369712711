import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function PlatformFee() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="platform-fee-boxs">
          <div className="platform-fee-card">
            <p>Platform fee per vehicle</p>
            <h2>
              <span>$10</span>/month
            </h2>
          </div>
          <div className="platform-fee-card">
            <p>Active vehicle</p>
            <h2>
              <span>10</span>
            </h2>
          </div>
        </div>
        <div className="earnings-checks">
          <div className="earnings-boxs">
            <p> Total platform fee</p>
            <h2>
              $100<span>/month</span>
            </h2>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
