import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import OtpVarification from "./Pages/OtpVarification";
import UploadDocuments from "./Pages/UploadDocuments";
import EditProfile from "./Pages/EditProfile";
import ChangePassword from "./Pages/ChangePassword";
import BankDetails from "./Pages/BankDetails";
import ProfileCreated from "./Pages/ProfileCreated";
import Dashboard from "./Pages/Dashboard";
import { ProfileProvider } from "./Context/ProfileContext";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";
import CarAdded from "./Pages/CardAdded";
import AddCar from "./Pages/AddCar";
import Vehicles from "./Pages/Vehicles";
import VehicleDetails from "./Pages/VehiclesDetails";
import TripDetails from "./Pages/TripDetails";
import Driver from "./Pages/Driver";
import Earning from "./Pages/Earning";
import PlatformFee from "./Pages/PlatformFee";
import TermConditions from "./Pages/TermConditions";
import BankDetailsProfile from "./Pages/BankDetailsProfile";
import Notifications from "./Pages/Notifications";
import Documents from "./Pages/Documents";
import AddDriver from "./Pages/AddDriver";

function App() {
  return (
    <div className="App test">
      <BrowserRouter>
        <ProfileProvider>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/sign-up" element={<SignUp />}></Route>
            <Route
              path="/otp-varification/:id"
              element={<OtpVarification />}
            ></Route>

            <Route path="/car-added" element={<CarAdded />}></Route>
            <Route
              path="/upload-documents"
              element={<UploadDocuments />}
            ></Route>
            <Route path="/bank-details" element={<BankDetails />}></Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/add-car" element={<AddCar />}></Route>

              <Route path="/edit-profile" element={<EditProfile />}></Route>
              <Route
                path="/change-password"
                element={<ChangePassword />}
              ></Route>
              <Route
                path="/profile-created"
                element={<ProfileCreated />}
              ></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/vehicles" element={<Vehicles />}></Route>
              <Route
                path="/vehicle-details/:id"
                element={<VehicleDetails />}
              ></Route>
              <Route path="/trip-details" element={<TripDetails />}></Route>
              <Route path="/driver" element={<Driver />}></Route>
              {/* rajinder pages create  */}
              <Route path="/earning" element={<Earning />}></Route>
              <Route path="/platform-fee" element={<PlatformFee />}></Route>
              <Route
                path="/term-and-conditions"
                element={<TermConditions />}
              ></Route>

              <Route
                path="/bank-details-profile"
                element={<BankDetailsProfile />}
              ></Route>
              <Route path="/notifications" element={<Notifications />}></Route>
              <Route path="/documents" element={<Documents />}></Route>
              <Route path="/driver-detail/:id" element={<AddDriver />}></Route>
            </Route>
          </Routes>
        </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
