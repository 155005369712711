import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../../Services/AdminApi";


 //  =======  Vehicles Listing  =======

 export const DriversManagemanet = createAsyncThunk(
    "DriversManagemanet",
    async (details) => {
      let url = `/fleet/myDrivers?page=${details?.page}&limit=${details?.limit}`;
  
      if (details.search) {
        url += `&search=${details.search}`;
      }

      if (details.type) {
        url += `&type=${details.type}`;
      }
  
      const data = await AdminAPI.get(url);
      console.log(data);
      return data;
    }
  );